/*
 * Shortcode: section-title.less
 * -----------------------------------------------
*/

.small-title {
	position: relative;

	i {
		font-size: 36px;
		margin-top: 7px;
		padding-top: 0px;
	}

	.title {
		margin-left: 15px;

		&:after {
			bottom: -6px;
			content: "";
			height: 1px;
			left: 45px;
			position: absolute;
			width: 45px;
		}
	}

}

.section-title {
	  .both-side-line::after {
		  content: "";
		  height: 2px;
		  position: absolute;
		  right: -40px;
		  top: 10px;
		  width: 30px;
		}

	  .both-side-line::before {
		  content: "";
		  height: 2px;
		  position: absolute;
		  left: -40px;
		  top: 10px;
		  width: 30px;
		}

		.top-side-line::after {
		  content: "";
		  height: 2px;
		  left: 0;
		  margin: 0 auto;
		  position: absolute;
		  right: 0;
		  top: -8px;
		  width: 40px;
		}

		.left-side-line::before {
		  content: "";
		  height: 2px;
		  left: 0;
		  position: absolute;
		  top: -8px;
		  width: 35px;
		}

		.right-side-line::before {
		  content: "";
		  height: 2px;
		  position: absolute;
		  right: 0;
		  top: -8px;
		  width: 35px;
		}

	.sub-title {
	  margin: 0;
	  position: relative;	
	  display: inline-block;  
	}

	.title {
	  margin-top: 0;
	  position: relative;
	}

	.icon i {
	  font-size: 80px;
	  position: absolute;
	  top: -7px;
	  opacity: 0.10;
	}


	p {
	  font-size: 14px;
	  font-style: italic;

		&.left-bordered {
			border-left: 2px solid @gray-silver;	  	
			padding-left: 10px;
		}

		&.right-bordered {
			border-right: 2px solid @gray-silver;	  	
			padding-right: 10px;
		}
	}
}

/*Theme Section Title*/
.section-title {
	margin-bottom: 30px;
	&.title-border {
		padding-left: 30px;

		&.title-right {
			border-left: 0;
    		padding-left: 0;
   			padding-right: 30px;
    		text-align: right;
		} 
	}
	
	&.icon-bg {
		p {
			font-size: 14px;
			font-style: italic;
			margin-bottom: 0;
		}

		.page-title {
			font-size: 38px;
			line-height: 52px;

			i {
				font-size: 42px;
				line-height: 1.3;
				position: absolute;
				opacity: 0.15;
				left: 0;
				right: 0;
				margin: 0 auto;
			}
		}

	}
}

.title-dots {
  background:  url("../images/title-dots.png") repeat scroll 0 0;
  height: 30px;
  margin-bottom: 20px;
}
.title-dots span {
  color: #fff;
  font-size: 17px;
  line-height: 30px;
  padding: 5px 10px;
}
.title-dots.small {
  height: 24px;
  margin-bottom: 15px;
}
.title-dots.small span {
  font-size: 11px;
  line-height: 23px;
}