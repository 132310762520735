@charset "utf-8";
/*------------------------------------------------------------------
[Master Stylesheet]

Project:        CharityPress
Version:        2.0
Last change:    02.20.2016
Primary use:    Attorney & Lawyer Business Template
Author:         ThemeMascot
URL:            http://themeforest.net/user/ThemeMascot
-------------------------------------------------------------------*/

// Import bootstrap variables and mixins for reference
@import (reference) "less-bootstrap/variables.less";
@import (reference) "less-bootstrap/mixins.less";

//fonts
@import "../css/font-awesome.min.css";
@import "../css/font-awesome-animation.min.css";
@import "../css/pe-icon-7-stroke.css";
/* @import "../css/elegant-icons.css"; */
/* @import "../css/stroke-gap-icons.css"; */
@import "../css/flaticon-set-charity.css";
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700,800);
@import url(http://fonts.googleapis.com/css?family=Playfair+Display:400,400italic,700,700italic);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,300,200,500,700,600,800);

 
// Table of contents
@import "less-charitypress/table-of-content.less";

 
// Initialize Variables
@import "less-charitypress/variables.less";
@import "less-charitypress/mixins.less";
// Typography
@import "less-charitypress/typography.less";

// Common Styles
@import "less-charitypress/common.less";
@import "less-charitypress/extra.less";
@import "less-charitypress/overlay.less";

// Header
@import "less-charitypress/header.less";

// Nav
@import "less-charitypress/nav.less";

// Content Blocks
@import "less-charitypress/topbar.less";
@import "less-charitypress/inner-header-title.less";
@import "less-charitypress/vertical-nav.less";
@import "less-charitypress/menu-full-page.less";
@import "less-charitypress/boxed-layout.less";
@import "less-charitypress/form.less";
@import "less-charitypress/side-push-panel.less";
@import "less-charitypress/box-hover-effect.less";
@import "less-charitypress/gallery-isotope.less";
@import "less-charitypress/sliders.less";
@import "less-charitypress/home.less";
@import "less-charitypress/about.less";
@import "less-charitypress/causes.less";
@import "less-charitypress/volunteer.less";
@import "less-charitypress/contact.less";
@import "less-charitypress/event.less";
@import "less-charitypress/practice-area.less";
@import "less-charitypress/services.less";
@import "less-charitypress/job.less";
@import "less-charitypress/shop.less";
@import "less-charitypress/blog.less";

// Shortcodes
@import "less-charitypress/shortcodes.less";


// Widgets
@import "less-charitypress/widgets.less";


// Footer
@import "less-charitypress/footer.less";