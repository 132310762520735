/*  
 * extra.less 
 * -----------------------------------------------
*/

/* -------- Heading Style ---------- */
.heading-border {
  border-bottom: 1px solid @gray-lightgray;
  margin: 30px 0;
  padding: 20px 0;
}

/* -------- FullCalendar - Plugin ---------- */
.fc-day-number {
  color: @black-333;
  
  &.fc-other-month {
    opacity: 0.4;
  }
}
/* scrolltofixed-container */
.scrolltofixed-container {
  position: static;
}
.home-boxes .bg-icon {
  bottom: 0;
  color: #fff;
  font-size: 58px;
  line-height: 1;
  opacity: 0.3;
  position: absolute;
  right: 0;
  transform: rotate(-40deg);
}
.mfp-close-btn-in .mfp-close {
  color: #fff;
  font-size: 48px;
}